import React from 'react'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'
import { H3, Text } from '@system'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.background.slite,
      maxWidth: '100%',
    },
    link: {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.text.headline,
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: '50px',
      '& a': {
        color: theme.palette.text.headline,
      },
    },
    container: {
      paddingBottom: '50px',
      maxWidth: '1200px',
    },
    featured: {
      paddingBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '10px',
      },
    },
    thumbnailContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      maxWidth: '675px',
      justifyContent: 'center',
      margin: '0 auto',
      paddingTop: '50px',
      paddingBottom: '50px',
    },
  }
})

const VideoGalleryModule = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <Container className={classes.root}>
        <Container className={classes.container}>
          <H3 className={classes.title}>{props.blok.title}</H3>
          <Box className={classes.featured}>
            {renderBloks(props.blok.featuredVideo)}
          </Box>
          <Box className={classes.thumbnailContainer}>
            {renderBloks(props.blok.thumbnailVideos)}
          </Box>
        </Container>
        <Text className={classes.link}>
          {!!props.blok.link && renderBloks(props.blok.link)}
        </Text>
      </Container>
    </SbEditable>
  )
}

export default VideoGalleryModule
